@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reggae+One&display=swap');

body {
  margin: 0;
  font-family: "Reggae One", system-ui;
}

.about {
  /*background: url(./images/bannar3.jpg) top center no-repeat;*/
  background-size: cover;
}

.hero {
  background: url(./images/bg.png) top center no-repeat;
  background-size: cover;
}

.footer {
  /*background: url(./images/bannar2.jpg) top center no-repeat;*/
  background-size: cover;
}

/*.cta {*/
/*  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;*/
/*}*/

.Typist .Cursor {
  display: inline-block; }
.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite; }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes animate {
  0%{
    transform: translateY(0) rotate(0deg);
    opacity: 0.5;
    border-radius: 0;
  }
  100%{
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #1d1f18;
  overflow: hidden;
  z-index: -1;
}
.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  /*background: rgba(255, 255, 255, 0.2);*/
  background-size: cover;
  animation: animate 19s linear infinite;
}




.background li:nth-child(0) {
  left: 59%;
  width: 160px;
  height: 160px;
  bottom: -160px;
  animation-delay: 1s;
}
.background li:nth-child(1) {
  left: 64%;
  width: 190px;
  height: 190px;
  bottom: -190px;
  animation-delay: 2s;
}
.background li:nth-child(2) {
  left: 51%;
  width: 188px;
  height: 188px;
  bottom: -188px;
  animation-delay: 1s;
}
.background li:nth-child(3) {
  left: 70%;
  width: 156px;
  height: 156px;
  bottom: -156px;
  animation-delay: 2s;
}
.background li:nth-child(4) {
  left: 6%;
  width: 121px;
  height: 121px;
  bottom: -121px;
  animation-delay: 1s;
}
.background li:nth-child(5) {
  left: 83%;
  width: 172px;
  height: 172px;
  bottom: -172px;
  animation-delay: 22s;
}
.background li:nth-child(6) {
  left: 53%;
  width: 138px;
  height: 138px;
  bottom: -138px;
  animation-delay: 19s;
}
.background li:nth-child(7) {
  left: 10%;
  width: 164px;
  height: 164px;
  bottom: -164px;
  animation-delay: 29s;
}
.background li:nth-child(8) {
  left: 72%;
  width: 114px;
  height: 114px;
  bottom: -114px;
  animation-delay: 23s;
}
.background li:nth-child(9) {
  left: 23%;
  width: 187px;
  height: 187px;
  bottom: -187px;
  animation-delay: 44s;
}
